import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Container } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ReactComponent as Facebook } from '../../../images/layout/Facebook.svg';
import { ReactComponent as Linkedin } from '../../../images/layout/Linkedin.svg';
import { ReactComponent as Youtube } from '../../../images/layout/YouTube.svg';

export interface FooterProps {
    aboutAsurionLabel: string;
    productsLabel: string;
    contactLabel: string;
    leadershipLabel: string;
    partnersLabel: string;
    faqLabel: string;
    termsOfUseLabel: string;
    privacyPolicyLabel: string;
    sitemapLabel: string;
    facebookUrl: string;
    linkedInUrl: string;
    youtubeUrl: string;
    copyrightText: string;
    footerLogo: string;
    solutoSubLinks?: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: 'black',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        containerCompress: {
            maxWidth: '1400px',
            // paddingLeft: '7vw',
            // paddingRight: '7vw',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        link: {
            flexGrow: 0.1,
            textDecoration: 'none',
            color: 'white !important',
            fontSize: '1rem',
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.875rem',
                fontWeight: 'normal',
            },
        },
        linkText: {
            textDecoration: 'none',
            color: 'white',
        },
        logoBox: {
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                textAlign: 'center',
            },
        },
        linkBox: {
            alignItems: 'center',
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'space-evenly',
            },
        },
        mobileLinkRow: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        socialBox: {
            [theme.breakpoints.up('md')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-evenly',
            },
        },
        socialLink: {
            display: 'flex',
            alignItems: 'center',
        },
        subtextBox: {
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        copyrightBox: {
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        sublinkBox: {
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-evenly',
            },
        },
        subtext: {
            fontSize: theme.spacing(1.75),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            textDecoration: 'none',
            color: '#A5AAAF',
            fontWeight: 'normal',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

export default function Footer(footerContent: FooterProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTaiwan: boolean = process.env.REACT_APP_LOCALE === 'zh-Hant-TW';

    return (
        <footer className={classes.root}>
            <Container className={classes.containerCompress}>
                <Grid container direction="row">
                    <Grid item md={2} sm={12} container direction="row" className={classes.logoBox}>
                        <Link className={classes.link} to="/">
                            <img src={footerContent.footerLogo} alt="footerLogo" />
                        </Link>
                    </Grid>
                    <Grid item md={8} sm={12} container direction="row" className={classes.linkBox}>
                        {isMobile ? (
                            <Grid container direction="column">
                                <Grid container direction="row" justifyContent="space-between" className={classes.mobileLinkRow}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.link}>
                                            <Link className={classes.link} to="/company/our-story">
                                                {footerContent.aboutAsurionLabel}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.link}>
                                            <Link className={classes.link} to="/what-we-do">
                                                {footerContent.productsLabel}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    {/* {!isTaiwan && (
                                        <Grid item xs={4}>
                                            <Typography variant="h6" className={classes.link}>
                                                <Link className={classes.link} to="/insights">
                                                    Insights
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    )} */}
                                </Grid>
                                <Grid container direction="row" justifyContent="space-between" className={classes.mobileLinkRow}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.link}>
                                            <Link className={classes.link} to="/contact-us">
                                                {footerContent.contactLabel}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.link}>
                                            <Link className={classes.link} to="/company/leadership">
                                                {footerContent.leadershipLabel}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.link}>
                                            <Link className={classes.link} to="/partners">
                                                {footerContent.partnersLabel}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justifyContent="space-between" className={classes.mobileLinkRow}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.link}>
                                            <Link className={classes.link} to="/faqs">
                                                {footerContent.faqLabel}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Fragment>
                                <Typography variant="h6" className={classes.link}>
                                    <Link className={classes.link} to="/company/our-story">
                                        {footerContent.aboutAsurionLabel}
                                    </Link>
                                </Typography>

                                <Typography variant="h6" className={classes.link}>
                                    <Link className={classes.link} to="/company/leadership">
                                        {footerContent.leadershipLabel}
                                    </Link>
                                </Typography>

                                <Typography variant="h6" className={classes.link}>
                                    <Link className={classes.link} to="/what-we-do">
                                        {footerContent.productsLabel}
                                    </Link>
                                </Typography>
                                {/* {!isTaiwan && (
                                    <Typography variant="h6" className={classes.link}>
                                        <Link className={classes.link} to="/insights">
                                            Insights
                                        </Link>
                                    </Typography>
                                )} */}
                                <Typography variant="h6" className={classes.link}>
                                    <Link className={classes.link} to="/partners">
                                        {footerContent.partnersLabel}
                                    </Link>
                                </Typography>

                                <Typography variant="h6" className={classes.link}>
                                    <Link className={classes.link} to="/contact-us">
                                        {footerContent.contactLabel}
                                    </Link>
                                </Typography>

                                <Typography variant="h6" className={classes.link}>
                                    <Link className={classes.link} to="/faqs">
                                        {footerContent.faqLabel}
                                    </Link>
                                </Typography>
                            </Fragment>
                        )}
                    </Grid>
                    <Grid item md={2} sm={12} container direction="row" className={classes.socialBox}>
                        <a className={classes.socialLink} href={footerContent.facebookUrl} target="_blank" rel="noreferrer">
                            <Facebook />
                        </a>
                        {/* <a className={classes.socialLink} href="#" target="_blank" rel="noreffer">
                            <Twitter />
                        </a> */}
                        <a className={classes.socialLink} href={footerContent.linkedInUrl} target="_blank" rel="noreferrer">
                            <Linkedin />
                        </a>
                        <a className={classes.socialLink} href={footerContent.youtubeUrl} target="_blank" rel="noreferrer">
                            <Youtube />
                        </a>
                    </Grid>
                </Grid>
                {footerContent?.solutoSubLinks?.length > 0 ? (
                    <Grid container direction="row" md={12} sm={12} className={classes.subtextBox}>
                        <Grid item md={6} sm={12} container direction="row" className={classes.copyrightBox}>
                            <Typography className={classes.subtext}>{footerContent.solutoSubLinks[0].label}</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} container direction="row" className={classes.sublinkBox}>
                            {footerContent.solutoSubLinks.map((link, index) => {
                                if (index > 0) {
                                    return (
                                        <Typography className={classes.subtext} key={`sublink-${index}`}>
                                            <Link className={classes.link} to={link.url}>
                                                <span className={classes.subtext}>{link.label}</span>
                                            </Link>
                                        </Typography>
                                    );
                                } else {
                                    return <div key={`sublink-0`}></div>;
                                }
                            })}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="row" md={12} sm={12} className={classes.subtextBox}>
                        <Grid item md={6} sm={12} container direction="row" className={classes.copyrightBox}>
                            <Typography className={classes.subtext}>{footerContent.copyrightText}</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} container direction="row" className={classes.sublinkBox}>
                            <Typography className={classes.subtext}>
                                <Link
                                    className={classes.link}
                                    to={
                                        (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TH' && '/tha/terms-of-use') ||
                                        (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'MY' && '/bah/terms-of-use') ||
                                        '/eng/terms-of-use'
                                    }
                                >
                                    <span className={classes.subtext}>{footerContent.termsOfUseLabel}</span>
                                </Link>
                            </Typography>

                            <Typography className={classes.subtext}>
                                <Link
                                    className={classes.link}
                                    to={
                                        (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'TH' && '/tha/privacy-policy') ||
                                        (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'MY' && '/bah/privacy-policy') ||
                                        (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'CN' && '/cn/privacy-policy') ||
                                        (process.env.REACT_APP_LOCALE.split('-').slice(-1)[0] === 'HK' && '/hk/privacy-policy') ||
                                        '/eng/privacy-policy'
                                    }
                                >
                                    <span className={classes.subtext}>{footerContent.privacyPolicyLabel}</span>
                                </Link>
                            </Typography>

                            <Typography className={classes.subtext}>
                                <Link className={classes.link} to="/sitemap">
                                    <span className={classes.subtext}>{footerContent.sitemapLabel}</span>
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </footer>
    );
}
